import React, { Component, Fragment } from 'react';

import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import CouponContainer from '../../container/coupon';
import { hide, show } from '../../redux/actions/loadingScreenAction';
import { Button } from '../../shared/components/button';
import FarmaciaPopularStatus from '../../shared/components/farmacia-popular-status';
import FiscalFlowStatus from '../../shared/components/fiscal-flow-status';
import ModalNew from '../../shared/components/modal-new';
import { ModalContent } from '../../shared/components/modal-new/styled';
import { withMessageBus } from '../../shared/utils/messageBus';
import { PlatformContext } from '../context';
import Footer from './footer';
import Header from './header';
import Main from './main';
import { ShortCutMenu } from './shortcut/shortcut-menu';
import Sidebar from './sidebar';
import { Container, Content, Coupon, MainWrapper, Views } from './styles';
import SubFooter from './sub-footer';

class Page extends Component {
  constructor(props) {
    super(props);
    this.showMessage = this.showMessage.bind(this);
    this.showError = this.showError.bind(this);
    this.closeMessageBox = this.closeMessageBox.bind(this);
    this.handleOrderUpdated = this.handleOrderUpdated.bind(this);

    PubSub.subscribe('pos.dialog', this.showDialog.bind(this));
    this.props.MessageBus.Subscribe(
      'pos.order.updated',
      this.handleOrderUpdated
    );
    this.props.MessageBus.Subscribe('pos.order.deleted', () =>
      this.setState({ info: null })
    );
    this.props.MessageBus.Subscribe('sale.done', this.clearInfo.bind(this));

    if (window.desktopApp) {
      window.desktopApp.subscribe(
        'machine.devices.desktop.error',
        (channel, msg) => {
          this.showMessageTef(channel, msg);
        }
      );

      window.desktopApp.subscribe(
        'machine.devices.tef.cancel.pending.operation.completed',
        (channel, data) => {
          this.eventCloseLoading(channel, data);
        }
      );
    }

    this.state = {
      message: undefined,
      title: undefined,
      info: null,
      hasInternalError: undefined,
      showMore: true,
    };
  }

  clearInfo = () => {
    this.setState({ info: null });
  };

  getChildContext() {
    return {
      showMessage: (message, title, callbackFn) =>
        this.showMessage(message, title, callbackFn),
      showError: (error) => this.showError(error),
    };
  }

  handleOrderUpdated(ch, payload) {
    if (payload.infoPbm && payload.infoPbm.pbmDescription) {
      this.setState({ info: `Venda PBM ${payload.infoPbm.pbmDescription}` });
    } else if (payload.infoLinxConecta) {
      this.setState({ info: `Venda Linx Conecta` });
    } else if (this.state.info) {
      this.setState({ info: null });
    }
  }

  showDialog(channel, data) {
    this.setState({
      message: data.message,
      title: undefined,
      hasInternalError: false,
    });
  }

  showMessage(message, title, callbackFn) {
    this.setState({ message, title, hasInternalError: false });
    this.callbackFn = callbackFn;
  }

  showError(error) {
    const isInternalError = error.status === 500;
    this.setState({
      message: error.message,
      title: error.title,
      hasInternalError: isInternalError,
      showMore: false,
      detail: error.detail,
    });
  }

  showMessageTef(canal, payload) {
    this.setState({
      message: payload.errors,
      title: undefined,
      hasInternalError: false,
      detail: null,
    });
  }

  closeMessageBox() {
    // this publish is being used only inside the front-end
    // to fix a problem where the dialog steals focus of some fields
    // the components can listen this message to reaply the focus when the dialog closes
    PubSub.publish('pos.dialog-closed', null);

    this.setState({
      message: undefined,
      title: undefined,
      hasInternalError: false,
    });
    if (this.callbackFn) {
      this.callbackFn();
      this.callbackFn = null;
    }
  }

  eventCloseLoading() {
    this.props.hide();
  }

  handleButtonShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  showExceptionMessage() {
    const maxTextLength = 255;
    const showButton = this.state.message.length > maxTextLength;
    const innerMessage =
      this.state.showMore || !showButton
        ? this.state.detail
        : `${this.state.message.slice(0, maxTextLength)}...`;
    const buttonText = this.state.showMore ? 'Exibir menos' : 'Exibir mais';
    return (
      <Fragment>
        <div
          className='div-error'
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <span class='label'>Mensagem: </span> {this.state.message}
          {this.state.showMore && (
            <div>
              <span className='label'>Causa: </span> {innerMessage}
            </div>
          )}
        </div>
        <Button
          className='btn btn-default btn-outline btn-show-hide'
          handleClick={this.handleButtonShowMore}
        >
          {buttonText}
        </Button>
      </Fragment>
    );
  }

  render() {
    const {
      children,
      hideCoupon,
      configRetailer,
      hideFooter,
      posType,
      retailer,
      isOnline,
      loginIsOpen,
    } = this.props;

    const showShortCut = configRetailer
      ? configRetailer.some(
          (x) => x.name === 'EXIBIR_ATALHOS_RAPIDOS_PDV' && x.value === '1'
        ) &&
        posType == 2 &&
        isOnline
      : false;

    const { viewManager } = this.props;
    const openedCards = Object.keys(viewManager).filter(
      (key) => viewManager[key]
    );
    let showShortCutMenu =
      openedCards[0] === 'showShortCutMenu' || openedCards[0] == undefined;

    const messageBox = this.state.message && (
      <ModalNew
        title={this.state.title || ''}
        close={() => this.closeMessageBox()}
        shouldHaveCloseButton
      >
        <ModalContent
          className={this.state.hasInternalError ? 'justify-start' : ''}
        >
          <p>
            {' '}
            {this.state.hasInternalError
              ? this.showExceptionMessage()
              : parse(this.state.message)}{' '}
          </p>
        </ModalContent>
      </ModalNew>
    );
    return (
      <Fragment>
        <Container>
          <Sidebar />
          <Content>
            <PlatformContext.Consumer>
              {(context) => <Header {...context} />}
            </PlatformContext.Consumer>
            <Views className='flex grow'>
              <MainWrapper className='flex column grow' hideCoupon={hideCoupon}>
                <Main className='grow'>{children}</Main>
                {showShortCutMenu && showShortCut && <ShortCutMenu />}
                {showShortCutMenu && (
                  <SubFooter retailer={retailer} loginIsOpen={loginIsOpen} />
                )}
                {hideFooter && <Footer info={this.state.info} />}
              </MainWrapper>
              {!hideCoupon && (
                <Coupon>
                  <PlatformContext.Consumer>
                    {(context) => (
                      <CouponContainer
                        toGoOption={context.toGoOption}
                        promotionImage={context.promotion}
                      />
                    )}
                  </PlatformContext.Consumer>
                </Coupon>
              )}
            </Views>
          </Content>
        </Container>
        {messageBox}
        <FarmaciaPopularStatus />
        <FiscalFlowStatus />
      </Fragment>
    );
  }
}

Page.childContextTypes = {
  showMessage: PropTypes.func,
  showError: PropTypes.func,
};

Page.propTypes = {
  children: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

Page.defaultProps = {
  children: null,
};

const mapStateToProps = (state) => ({
  viewManager: state.viewManager,
  hideCoupon: state.viewManager.hideCoupon,
  showShortCutMenu: state.viewManager.showShortCutMenu,
  configRetailer: state.configRetailer.configs,
  retailer: state.retailer,
  posType: state.config?.posType,
  isOnline: state.pdvContingency.isOnline,
  loginIsOpen: state.util.loginIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
  show: (param) => dispatch(show({ text: param })),
  hide: () => dispatch(hide()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMessageBus(Page));

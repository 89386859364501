import React, { useEffect, useState } from 'react';

import ReactModal from 'react-modal';
import Button from '../../../shared/components/button';
import './message-clients.scss';

const titleMessageClients =
  'Aviso importante: Migração do seu sistema Linx Farma Cloud!';
const messageClients = `
<p>Caro cliente, informamos que a Linx criou um plano de migração do sistema Linx Farma Cloud para o sistema Linx Big Farma,
sem custo nenhum e com agendamento prévio.</p>
<p>No dia <b>20 de fevereiro de 2025</b>, o Linx Farma Cloud será <b>desativado</b> completamente. É indispensável que você procure a franquia que te atende
para agendar a sua migração antes da data limite acima.</p>
<p>Essa será uma mudança importante que vai proporcionar uma evolução no seu sistema de gestão, ampliando funcionalidades e benefícios para o
seu negócio. Conte com a gente!</p>`;

export const MessageClients = ({ loginIsOpen }) => {
  const [showModal, setShowModal] = useState(true);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '700px',
      padding: '1.5rem',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.50)',
      display: 'block',
      padding: '1.2rem',
    },
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (loginIsOpen) {
      setShowModal(true);
    }
  }, [loginIsOpen]);
  return (
    <div id='message-clients-container'>
      <div className='title-message'>
        <i className='material-icons'>info</i>

        <h3>{titleMessageClients}</h3>
      </div>

      <div
        className='message-text'
        dangerouslySetInnerHTML={{ __html: messageClients }}
      />

      <ReactModal
        isOpen={showModal}
        shouldCloseOnEsc={false}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className='modal-message-clients-container'>
          <div className='title-message'>
            <i className='material-icons'>info</i>

            <h3>{titleMessageClients}</h3>
          </div>

          <div
            className='message-text'
            dangerouslySetInnerHTML={{ __html: messageClients }}
          />

          <div className='message-footer'>
            <Button className='btn btn-outline' onClick={handleClose}>
              OK
            </Button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
